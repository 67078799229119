import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  JoinForm,
} from "@components"

const JoinKobofunds = ({ location }) => (
  <Layout>
    <Seo title="Join Kobofunds" />
    <JoinForm location={location} />
  </Layout>
)

export default JoinKobofunds
